"use client";

import React, { useState, useEffect } from 'react';

const LoaderOverlay = () => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => setLoading(false), 1000);
        return () => clearTimeout(timer);
    }, []);

    if (!loading) return null;

    return (
        <div style={{
            position: 'fixed',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: 'rgba(0,0,0,1)', zIndex: 1000 }}>Loading...</div>
    );
};

export default LoaderOverlay;

'use client';
import { FC } from 'react';
import styles from './CarouselEvent.module.scss';
import { EventArrElement } from '@/models/EventModels';

import Verified from '../../app/assets/icons/verified.svg';
import { usePathname } from 'next/navigation';
import { mainPath } from '@/app/routes/MainPath';
import Prev from '../../app/assets/icons/arrow-prev.svg';
import Next from '../../app/assets/icons/arrow-next.svg';
import Image from 'next/image';
import ButtonElement from '../ButtonElement';
import { useTranslations } from 'next-intl';

interface CarouselEventProps {
  className?: string;
  data: EventArrElement;
  handleNext: () => void;
  handlePrev: () => void;
}

const CarouselEvent: FC<CarouselEventProps> = ({
  className = '',
  data,
  handleNext,
  handlePrev,
}) => {
  const location = usePathname();
  const t = useTranslations('CAROUSEL_EVENT');

  return (
    <div className={styles.container}>
      {
        <button className={`${styles.prev} custom-swiper-button-prev`} onClick={handlePrev}>
          <Image width={22} height={32} src={Prev} alt="nav" />
        </button>
      }

      <div className={`${styles.root} ${className}`}>
        <Image
          aria-details="concert"
          className={styles.poster_image}
          width={1600}
          height={800}
          src={data?.poster_image || ''}
          alt={data.title}
        />
        <div className={styles.organization}>
          <Image
            className={styles.organization__icon}
            width={44}
            height={44}
            src={data?.organization.image || ''}
            alt={data.organization.slug}
          />

          <div className={styles.organization__texts}>
            <p className={styles.organization__title}>{data?.organization.name || ''}</p>
            {/*<p className={styles.organization__verified}>*/}
            {/*  <span>*/}
            {/*    <Image src={Verified} height={14} alt="verified" width={14} />*/}
            {/*  </span>*/}
            {/*  {t('verified')}*/}
            {/*</p>*/}
          </div>
        </div>
        <div className={styles.event}>
          <b className={styles.event__title}>ALBUM PRESENTATION</b>
          <p className={styles.event__subtitle}>{data?.title || ''}</p>

          <ButtonElement
            size="small"
            className={styles.event__button}
            prefetch={false}
            href={`${mainPath.single_event.path}/${data.organization?.slug}/${data.id}?prev=${location}`}
            text="See tickets">
            {t('see')}
          </ButtonElement>
        </div>
      </div>

      {
        <button className={`${styles.next} custom-swiper-button-next`} onClick={handleNext}>
          <Image width={22} height={32} src={Next} alt="nav" />
        </button>
      }
    </div>
  );
};

export default CarouselEvent;

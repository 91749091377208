'use client';
import { FC, useRef } from 'react';
import styles from './EventsCarousel.module.scss';
import { EventArrElement } from '@/models/EventModels';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

import CarouselEvent from '../CarouselEvent';

interface EventsCarouselProps {
  className?: string;
  events: EventArrElement[];
}

const EventsCarousel: FC<EventsCarouselProps> = ({ className = '', events }) => {
  const swiperSettings = {
    centeredSlides: true,
    grabCursor: true,
    initialSlide: 1,
    freeMode: false,
    loop: true,
    mousewheel: false,
    keyboard: {
      enabled: true,
    },
    autoplay: {
      delay: 8000,
      disableOnInteraction: false,
    },
    pagination: {
      el: '.swiper-pagination',
      dynamicBullets: false,
      clickable: true,
    },
    navigation: {
      nextEl: '.custom-swiper-button-next',
      prevEl: '.custom-swiper-button-prev',
    },

    //modules: [Autoplay, Pagination, Navigation],

    breakpoints: {
      1024: {
        slidesPerView: 1.2,
        spaceBetween: 60,
      },
      768: {
        slidesPerView: 1,
        spaceBetween: 0,
      },
    },
  };

  const swiperRef = useRef<any>(null);

  const handlePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };

  const handleNext = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  return (
    <div className={`${styles.root} ${className}`}>
      <div className={styles.slider}>
        <Swiper {...swiperSettings} onSwiper={(swiper) => (swiperRef.current = swiper)}>
          {events &&
            events.length > 0 &&
            events.map((event, idx) => (
              <SwiperSlide key={event.id}>
                <CarouselEvent
                  handlePrev={handlePrev}
                  handleNext={handleNext}
                  data={event}></CarouselEvent>
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </div>
  );
};

export default EventsCarousel;
